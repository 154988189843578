import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import { useGetUndeliveredOrdersQuery } from '../../slices/ordersApiSlice';




const OrderNotDeliveredListScreen = () => {

  const { data: orders, isLoading, error } = useGetUndeliveredOrdersQuery();
  const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };


  return (
    <>
      <h1>Orders Awaiting Delivery</h1>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>ID</th>
              <th>User</th>
              <th>Ordered</th>
              <th>Total</th>
              <th>Paid</th>
              <th>Delivered</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order._id}>
              <td><a href={`/order/${order._id}`}> {order._id} </a></td>
                <td>{order.user && order.user.name}</td>
                <td>{new Date(order.createdAt).toLocaleString('en-US', options)}</td>
                <td>Rs. {order.totalPrice}</td>
                <td>
                  {order.isPaid ? (
                    new Date(order.paidAt).toLocaleString('en-US', options)
                  ) : (
                    <FaTimes style={{ color: 'red' }} />
                  )}
                </td>
                <td>
                  {order.isDelivered ? (
                    new Date(order.deliveredAt).toLocaleString('en-US', options)
                  ) : (
                    <FaTimes style={{ color: 'red' }} />
                  )}
                </td>
                <td>
                  <LinkContainer to={`/order/${order._id}`}>
                    <Button variant='dark' className='btn-sm'>
                      Details
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default OrderNotDeliveredListScreen;
