import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useGetProductsQuery } from '../slices/productsApiSlice';
import { Link } from 'react-router-dom';
import Product from '../components/Product';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import ProductCarousel from '../components/ProductCarousel';
import Meta from '../components/Meta';
import ScrollingText from '../components/ScrollingText';
const scrollingText = "We do not store any dishes. Please place your order at least 4 hours earlier. Thank you.";
// const x = process.env.SCROLLING_TEXT;
// console.log("scrollingText1: " + x);


const HomeScreen = () => {
  const { pageNumber, keyword } = useParams();

  const { data, isLoading, error } = useGetProductsQuery({
    keyword,
    pageNumber,
  });

  return (
    <>
    
      {!keyword ? (     
           <ProductCarousel/>
      ) : (
        <Link to='/' className='btn btn-light mb-4'>
          Go Back 
        </Link>
      )}

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Meta />
          <div>
            <ScrollingText scrollingText= {scrollingText} />
          </div>
          <h1>Latest Dishes</h1>
          <Row>
            {data.products.map((product) => (
              <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                <Product product={product} />
              </Col>
            ))}
          </Row>
          <Paginate
            pages={data.pages}
            page={data.page}
            keyword={keyword ? keyword : ''}
          />
        </>
      )}
    </>
    
  );
};

export default HomeScreen;
