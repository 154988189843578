import { Helmet } from 'react-helmet-async';

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keyword' content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Juna's Kitchen",
  description: 'We sell the best food for cheap',
  keywords: 'Food, Lunch, Dinner',
};

export default Meta;
